import { ServerError } from '@apollo/client'
import { ErrorHandler, onError } from '@apollo/client/link/error'
import { store } from 'src/store'
import { logout } from 'src/store/thunks/auth'

const logErrors: ErrorHandler = ({
  graphQLErrors,
  operation,
  networkError,
}) => {
  if (graphQLErrors) {
    const nonUserInputError = graphQLErrors.filter(
      error => error.extensions?.code !== 'BAD_USER_INPUT',
    )

    nonUserInputError.forEach(error => {
      if (error.extensions?.statusCode === 401) store.dispatch(logout())
      else
        console.warn('Error: GQL error', {
          error,
          response: error?.extensions?.response,
        })
    })
  }

  if (networkError) {
    const error = networkError as ServerError
    const { operationName, getContext } = operation
    console.warn('Error: Network GQL error', {
      operationName,
      networkError,
      response: getContext?.()?.response,
    })
    if (error.statusCode === 401) store.dispatch(logout())
  }
}

export const errorLogger = onError(logErrors)
