export const primaryColor = '#a22c5d'
export const secondaryColor = '#3c9095'
export const successColor = '#589c52'
export const infoColor = '#3987d8'
export const warningColor = '#ffcc00'
export const errorColor = '#de433e'
export const linkBlue = '#465cb4'

export const adminColor = '#5b399c'
export const tigerColor = '#4e8588'
export const loadingColor = '#686868'
export const peerPortalColor = '#3c9095'
export const settingsColor = '#3c9095'

export const borderGray = '#ebf0f2'
export const headerFontColor = '#5e4e54'

export const lovelyPurple = '#a22d5d'
export const gunmetalGray = '#4a5459'
export const white = '#ffffff'
