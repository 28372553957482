import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
} from '@mui/material'
import { useId } from 'react'
import {
  FieldError,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

interface Option {
  key: string | number
  value: string | number
  label: string | JSX.Element
}

export interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error?: FieldError
  options: Option[]
  label?: string | JSX.Element
  fullWidth?: boolean
  sx?: SxProps
  selectProps?: SelectProps
  emptyOptionLabel?: JSX.Element
}

export const ControlledSelect = <T extends FieldValues>({
  control,
  name,
  error,
  label,
  options,
  fullWidth,
  sx,
  selectProps,
  emptyOptionLabel,
}: Props<T>) => {
  const { field } = useController({ control, name })
  const id = useId()

  return (
    <FormControl sx={sx} fullWidth={fullWidth}>
      <InputLabel htmlFor={`${name}-select`} id={id}>
        {label}
      </InputLabel>
      <Select
        {...selectProps}
        {...field}
        inputProps={{ id: `${name}-select` }}
        labelId={id}
        label={label}
        error={!!error}>
        {emptyOptionLabel && <MenuItem value="">{emptyOptionLabel}</MenuItem>}
        {options.map(({ key, label, value }) => (
          <MenuItem key={key} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{error?.message as string}</FormHelperText>
    </FormControl>
  )
}
